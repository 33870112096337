import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import { Transition } from 'react-transition-group';
import TableFilters from '../../components/pages/TransactionsPage/TableFilters';
import TransactionsTable from '../../components/pages/TransactionsPage/TransactionsTable';
import useSearchParams from '../../hooks/useSearchParams';
import {subscriptionsHelpers} from '../../components/pages/SubscriptionsPage/subscriptionsHelpers';
import LoadMoreButton from '../../components/pages/TransactionsPage/LoadMoreButton';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import {
  StyledTransactionsPageContainer,
  StyledTransactionsPageContent,
} from './StyledTransactionsPage';
import TransactionDetailsPanel from '../../components/pages/TransactionsPage/TransactionDetailsPanel';
import TransactionDetails from '../../components/pages/TransactionsPage/TransactionDetails';
import NoSearchResults from '../../components/NoSearchResults';
import Empty from '../../components/Empty';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import AddInvoiceModal from '../../components/pages/TransactionsPage/AddInvoiceModal/AddInvoiceModal';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import {errorHandler} from '../../state/services/request';
import {
  bankingActions,
  companyActions,
  expenseActions,
  invoicesActions,
  subscriptionActions,
  transactionActions
} from '../../state/actions';
import {helpers} from '../../helpers';
import {ReactComponent as EmptySvgImage} from '../../static/images/pages/transactions/empty-transactions.svg';
import {transactionsHelpers} from '../../components/pages/TransactionsPage/transactionsHelpers';
import {SCAActionsConstants} from '../../constants';
import {scaHelpers} from '../../scaHelpers';

const gObjProp = helpers.getObjProp;

const {checkIsEnabledLoadMore} = subscriptionsHelpers;

const initialLoadedTransactionMaxCount = 25;
const duration = 300;
const numberOfMonthsWithoutAuth = 3;
const tableElementId = 'transactions-page-table-content';

const {details: detailsStyles, table: tableStyles} = transactionsHelpers.getAnimationStyles({duration});

const {TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION, TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION} = SCAActionsConstants;

const TransactionsPage = ({
  addInvoiceModalProps,
  batchCreateInvoices,
  closeAddInvoiceModal,
  getBatchExpenses,
  getCards,
  getEmployees,
  getSubscription,
  getTags,
  getTransactionsList,
  linkExpense,
  unlinkExpense,
  openAddInvoiceModal,
  employees,
  wireDetails,
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [loading, setLoading] = useState(true);
  const [defaultTransactions, setDefaultTransactions] = useState([]);
  const [searchedTransactions, setSearchedTransactions] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loadMoreProps, setLoadMoreProps] = useState({loading: false, isEnabled: false, months: 1});
  const [filterParams, setFilterParams] = useState(null);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [search, setSearch] = useState('');
  const [isFixedDetailsWindow, setIsFixedDetailsWindow] = useState(false);
  const [detailsWindowFixedHeight, setDetailsWindowFixedHeight] = useState(0);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [activeOperationName, setActiveOperationName] = useState(null);

  const searchParams = useSearchParams().allParams;

  const tableNodeRef = useRef(null);
  const detailsNodeRef = useRef(null);

  const selectedSubscription = useMemo(() => {
    let subscription;
    const expense = gObjProp(selectedTransaction, 'expense');
    const subscriptionId = gObjProp(expense, 'subscription_id');
    if (isOpenDetails && subscriptionId) subscription = subscriptions.find(s => s.id === subscriptionId);
    return subscription;
  }, [subscriptions, isOpenDetails, selectedTransaction]);

  useEffect(() => {
    let offsetTop = helpers.getElementOffsetTop(`#${tableElementId}`);
    if (offsetTop !== detailsWindowFixedHeight) setDetailsWindowFixedHeight(offsetTop);
  }, [isFixedDetailsWindow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    transactionsHelpers.addFixedDetailsWindowScrollEventListener({
      detailsWindowFixedHeight, isFixedDetailsWindow, setIsFixedDetailsWindow
    });
  }, [isFixedDetailsWindow, detailsWindowFixedHeight]);

  const emptyT = (key) => t(`transactions:empty.${key}`);

  useEffect(() => {
    getCards();
    getEmployees();
    getTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wireDetails && wireDetails.creationDate) {
      setLoadMoreProps({
        ...loadMoreProps,
        loading: false,
        months: 1,
        isEnabled: checkIsEnabledLoadMore(wireDetails.creationDate, numberOfMonthsWithoutAuth),
      });
    }
  }, [wireDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const transactionsWithExpense = transactionsHelpers.getTransactionsWithExpense({expenses, employees, transactions});
    const groupedTransactions = transactionsHelpers.getGroupedTransactions(transactionsWithExpense);

    setDefaultTransactions(transactionsWithExpense);
    setSearchedTransactions(groupedTransactions);
  }, [transactions, expenses, employees]);

  const getLoadMoreTransactionsSuccessCallback = (latestTransactions, loadMoreProps) => {
    const expensesIds = latestTransactions.map(t => t.expense_id).filter(value => value);
    const isExistsExpensesIds = expensesIds.length > 0;

    setTransactions([...transactions, ...latestTransactions]);
    setLoadMoreProps({...loadMoreProps, loading: false});

    if (isExistsExpensesIds) {
      getBatchExpenses(
        {expenses_ids: expensesIds},
        (latestExpenses) => setExpenses([...expenses, ...latestExpenses])
      );
    } else if (!isExistsExpensesIds && expenses.length > 0) {
      setExpenses([]);
    }
  }

  const getTransactionsSuccessCallback = (transactions) => {
    const expensesIds = transactions.map(t => t.expense_id).filter(value => value);
    const isExistsExpensesIds = expensesIds.length > 0;

    setTransactions(transactions);
    setLoading(false);

    if (isExistsExpensesIds) {
      getBatchExpenses(
        {expenses_ids: expensesIds},
        (expenses) => setExpenses(expenses)
      );
    } else if (!isExistsExpensesIds && expenses.length > 0) {
      setExpenses([]);
    }
  }

  const handleOnFilterChange = (query) => {
    setFilterParams(query);
    !loading && setLoading(true);
    getTransactionsListPromises({
      operationName: TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION,
      query,
      successCallback: getTransactionsSuccessCallback,
      errorCallback: () => setLoading(false)
    });
  }

  const getTransactionsListPromises = ({
    query,
    successCallback,
    errorCallback,
    subtractMonthsCount,
    operationName
  }) => {
    const errorFunc = (response) => errorHandler(response, errorCallback);

    const transactionRequest = (subtractMonthsCount, successFunc) => {
      const requestQuery = subscriptionsHelpers.getTransactionRequestQuery(query, subtractMonthsCount);
      getTransactionsList({
        headers: scaHelpers.getAuthHeaders(operationName || activeOperationName),
        query: requestQuery,
        successCallback: successFunc,
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
            errorCallback: errorFunc
          });
        }
      });
    }

    let storedTransactions = [];

    activeOperationName !== operationName && setActiveOperationName(operationName);

    if (subtractMonthsCount) {
      transactionRequest(
        subtractMonthsCount,
        (response) => successCallback && successCallback(response)
      )
    } else {
      const loadTransactions = (index, maxLength) => {
        const successFunc = (data) => {
          storedTransactions = [...storedTransactions, ...data];
          let nextIndex = index + 1;
          if (loadMoreProps.months !== nextIndex) {
            setLoadMoreProps({
              ...loadMoreProps,
              months: nextIndex,
              isEnabled: true
            });
          }
          if (nextIndex < maxLength && storedTransactions.length <= initialLoadedTransactionMaxCount) {
            setTransactions(storedTransactions);
            loadTransactions(nextIndex, maxLength);
          } else {
            successCallback && successCallback(storedTransactions);
          }
        }

        transactionRequest(index, successFunc);
      }

      loadTransactions(0, numberOfMonthsWithoutAuth);
    }
  }

  const handleOnSearchChange = (value) => {
    const lowerCaseValue = (value || '').toLowerCase();
    const transactions = defaultTransactions.filter(t => {
      const source = gObjProp(t, 'source') || gObjProp(t, 'description');
      return source.toLowerCase().includes(lowerCaseValue);
    });
    const groupedTransactions = transactionsHelpers.getGroupedTransactions(transactions);
    setSearch(value);

    setSearchedTransactions(groupedTransactions);
  }

  const handleLoadMore = () => {
    if (loadMoreProps.isEnabled) {
      const {months} = loadMoreProps;
      const subtractMonthsCount = months + 1;
      const updateLoadMoreProps = {
        ...loadMoreProps,
        loading: true,
        months: subtractMonthsCount,
        isEnabled: checkIsEnabledLoadMore(wireDetails.creationDate, subtractMonthsCount),
      }
      setLoadMoreProps({...loadMoreProps, loading: true});

      getTransactionsListPromises({
        operationName: TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
        query: filterParams,
        successCallback: (transactions) => getLoadMoreTransactionsSuccessCallback(transactions, updateLoadMoreProps),
        errorCallback: () => {
          setLoading(false);
          setLoadMoreProps({...updateLoadMoreProps, loading: false});
        },
        subtractMonthsCount: months
      });
    }
  }

  const onExpenseUpdate = (updatedExpense, actionType) => {
    if (updatedExpense) {
      let updatedExpenses = helpers.getUpdatedBatchExpensesList(expenses, updatedExpense, actionType);
      setExpenses(updatedExpenses);
      if (selectedTransaction) {
        setSelectedTransaction({
          ...selectedTransaction,
          expense: {
            ...selectedTransaction.expense,
            ...updatedExpense
          }
        })
      }
    }
  }

  const handleInvoiceRemove = (successCallback, errorCallback) => {
    transactionsHelpers.removeInvoiceFromTransaction({
      t,
      transaction: selectedTransaction,
      successCallback,
      errorCallback,
      unlinkExpense,
      onExpenseUpdate
    });
  }

  const handleCloseDetails = () => setIsOpenDetails(false);

  const handleOpenDetails = (transactionId) => {
    let subscriptionId = null;
    let transaction = searchedTransactions.find(d => d.id === transactionId);
    if (transaction) {
      const expense = expenses.find(e => e.id === transaction.expense_id);
      if (expense) {
        subscriptionId = expense.subscription_id;
        transaction = {...transaction, expense: {...transaction.expense, ...expense}}
      }
    }
    if (subscriptionId) {
      const subscription = subscriptions.find(s => s.id === subscriptionId);
      if (subscription === undefined) {
        getSubscription(
          subscriptionId,
          (subscription) => setSubscriptions([...subscriptions, subscription])
        );
      }
    }
    setSelectedTransaction(transaction);
    setIsOpenDetails(true);
  }

  const handleOkAttachmentModal = (formData, successCallback, errorCallback) => {
    transactionsHelpers.addInvoiceToTransaction({
      addInvoiceModalProps,
      formData,
      successCallback,
      errorCallback,
      batchCreateInvoices,
      closeAddInvoiceModal,
      linkExpense,
      onExpenseUpdate
    });
  }

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    loading && setLoading(false);
    if (activeOperationName === TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION) {
      setLoadMoreProps({...loadMoreProps, loading: false});
    }
  }

  const handleOnSuccessAuth = () => {
    handleCloseAuthModal();
    if (activeOperationName === TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION) {
      setLoading(true);
      handleOnFilterChange(filterParams);
    } else {
      handleLoadMore();
    }
  }

  const isNotExistsTransactions = !loadMoreProps.isEnabled && !loading && transactions.length === 0;

  const enableExport = filterParams && filterParams.hasOwnProperty('card_id_in') ? filterParams.card_id_in.length === 0 : true;

  return (
    <>
      <PageDocumentDetails title={t('pageTitles.transactionsList')} />
      <BalancePageHeader breadcrumbs={[{title: t('transactions')}]} />
      <StyledTransactionsPageContainer>
        {isNotExistsTransactions ? (
          <Empty
            description={emptyT('description')}
            image={<EmptySvgImage />}
            title={emptyT('title')}
          />
        ) : (
          <>
            <Transition nodeRef={tableNodeRef} in={isOpenDetails} timeout={duration}>
              {state => (
                <StyledTransactionsPageContent
                  id={tableElementId}
                  style={{
                    ...tableStyles.default,
                    ...tableStyles[state]
                  }}
                >
                  <TableFilters
                    enableExport={enableExport}
                    onFilter={handleOnFilterChange}
                    onSearch={handleOnSearchChange}
                    searchParams={searchParams}
                  />
                  {search !== '' && searchedTransactions.length === 0 ? (
                    <NoSearchResults
                      spinning={loading}
                      value={search}
                    />
                  ) : (
                    <>
                      <TransactionsTable
                        data={searchedTransactions}
                        emptyMessage={loading ? ' ' : undefined}
                        isCollapsed={isOpenDetails}
                        loading={loading}
                        onRowClick={handleOpenDetails}
                        onInvoiceEdit={openAddInvoiceModal}
                      />

                      <LoadMoreButton
                        isEnabled={loadMoreProps.isEnabled}
                        loading={loading || loadMoreProps.loading}
                        modalTitle={t('showMoreTransactions')}
                        onClick={handleLoadMore}
                        size='large'
                      >
                        {t('showMore')}
                      </LoadMoreButton>
                    </>
                  )}
                </StyledTransactionsPageContent>
              )}
            </Transition>
            <Transition nodeRef={detailsNodeRef} in={isOpenDetails} timeout={duration}>
              {state => (
                <TransactionDetailsPanel
                  contentClassName='w-100'
                  onBack={handleCloseDetails}
                  fixed={isFixedDetailsWindow}
                  hiddenOverflow={!isOpenDetails}
                  style={{
                    ...detailsStyles.default,
                    ...detailsStyles[state]
                  }}
                >
                  <TransactionDetails
                    onExpenseUpdate={onExpenseUpdate}
                    onInvoiceEdit={openAddInvoiceModal}
                    onInvoiceRemove={handleInvoiceRemove}
                    subscription={selectedSubscription}
                    transaction={selectedTransaction}
                  />
                </TransactionDetailsPanel>
              )}
            </Transition>
          </>
        )}

        <AddInvoiceModal
          onCancel={closeAddInvoiceModal}
          open={addInvoiceModalProps.open}
          transaction={addInvoiceModalProps.transaction}
          onOk={handleOkAttachmentModal}
        />

        <AuthenticationWindow
          {...authWindowProps}
          authModalProps={{title: `${t('transactions')} ${t('loading')}`}}
          handleCancel={handleCloseAuthModal}
          onSuccess={handleOnSuccessAuth}
          operationName={activeOperationName}
        />

      </StyledTransactionsPageContainer>
    </>
  );
}

const mapStateToProps = state => {
  const {employees} = state.company;
  const {addInvoiceModalProps, wireDetails} = state.transaction;

  return {
    addInvoiceModalProps,
    employees,
    wireDetails
  }
}

const mapDispatchToProps = {
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  closeAddInvoiceModal: transactionActions.closeAddInvoiceModal,
  getBatchExpenses: expenseActions.getBatchExpenses,
  getCards: bankingActions.getCardsList,
  getTransactionsList: transactionActions.getTransactionsList,
  getTags: companyActions.getTags,
  getEmployees: companyActions.getEmployees,
  getSubscription: subscriptionActions.getSubscription,
  linkExpense: invoicesActions.linkExpense,
  unlinkExpense: invoicesActions.unlinkExpense,
  openAddInvoiceModal: transactionActions.openAddInvoiceModal
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
