import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {Alert, Avatar, Radio, Space} from 'antd';
import {UnmountClosed} from 'react-collapse';
import {connect} from 'react-redux';
import {
  StyledAddTransactionModal,
  StyledAddTransactionModalSpace,
  StyledAddTransactionModalInvoiceDetailsHeaderSpace,
  StyledAddTransactionModalInvoiceDetailsHeader,
  StyledAddTransactionModalTransactionRadio,
  StyledAddTransactionModalRadioDescription,
  StyledAddTransactionModalAttachmentPreview
} from './StyledAddTransactionModal';
import SpinSmall from '../../../SpinSmall';
import InvoiceAttachmentPreview from '../../TransactionsPage/InvoiceAttachmentPreview';
import {transactionsHelpers} from '../../TransactionsPage/transactionsHelpers';
import LoadMoreButton from '../../TransactionsPage/LoadMoreButton';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import {helpers} from '../../../../helpers';
import {transactionActions} from '../../../../state/actions';
import {subscriptionsHelpers} from '../../SubscriptionsPage/subscriptionsHelpers';
import {SCAActionsConstants} from '../../../../constants';
import {scaHelpers} from '../../../../scaHelpers';

const {checkIsEnabledLoadMore, getTransactionRequestQuery} = subscriptionsHelpers;
const numberOfMonthsWithoutAuth = 3;

const {TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION, TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION} = SCAActionsConstants;

const AddTransactionModal = ({
  dispatch,
  invoice,
  loading,
  getTransactions,
  onOk,
  open,
  wireDetails,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);
  const [error, setError] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(undefined);
  const [transactions, setTransactions] = useState({
    data: [],
    loaded: false
  });
  const [loadMoreProps, setLoadMoreProps] = useState({
    loading: false,
    isEnabled: false,
    months: 1
  });
  const [query, setQuery] = useState({});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});

  const getOperationName = (months) => months >= numberOfMonthsWithoutAuth ? TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION : TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION;

  useEffect(() => {
    (!open && selectedTransaction) && setSelectedTransaction(undefined);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const activeOperationName = useMemo(() => getOperationName(loadMoreProps.months), [loadMoreProps]);

  useEffect(() => {
    if (open && !transactions.loaded) loadTransactions();
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wireDetails && wireDetails.creationDate) {
      setLoadMoreProps({
        ...loadMoreProps,
        loading: false,
        months: 1,
        isEnabled: checkIsEnabledLoadMore(wireDetails.creationDate, numberOfMonthsWithoutAuth),
      });
    }
  }, [wireDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const gObjProp = (key, defaultValue) => helpers.getObjProp(invoice, key, defaultValue);

  const amount = helpers.getMoneyView(gObjProp('amount') || 0);
  const date = gObjProp('date');
  const vendor = gObjProp('vendor');

  const attachment = {
    file: gObjProp('file_url'),
    name: gObjProp('file_name')
  }

  const disabledOkButton = selectedTransaction === undefined;

  const trans = (key, props) => t(`invoices:modal.addTransaction.${key}`, props);

  const loadTransactions = ({months = 0, successCallback, errorCallback} = {}) => {
    const requestQuery = getTransactionRequestQuery(query, months);
    const operationName = getOperationName(months);
    getTransactions({
      headers: scaHelpers.getAuthHeaders(operationName),
      query: requestQuery,
      successCallback: (data) => {
        const filteredTransactions = data.filter(transaction => !transactionsHelpers.isReturnedTransaction(transaction) && !transaction.is_incoming);
        setTransactions({...transactions, data: [...transactions.data, ...filteredTransactions], loaded: true});
        successCallback && successCallback();
        setQuery(query);
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
          errorCallback
        });
      }
    });
  }

  const handleSubmitFiles = () => {
    if (onOk && !disabledOkButton) {
      let transaction = transactions.data.find(t => t.id === selectedTransaction);
      onOk(
        transaction.expense_id,
        null,
        (error) => setError(error?.message || error)
      );
    }
  }

  const handleLoadMore = () => {
    if (loadMoreProps.isEnabled) {
      const {months} = loadMoreProps;
      const subtractMonthsCount = months + 1;
      const updateLoadMoreProps = {
        ...loadMoreProps,
        loading: false,
        months: subtractMonthsCount,
        isEnabled: checkIsEnabledLoadMore(wireDetails.creationDate, subtractMonthsCount),
      }
      setLoadMoreProps({...loadMoreProps, loading: true});
      loadTransactions({
        months,
        successCallback: () => setLoadMoreProps(updateLoadMoreProps),
        errorCallback: () => setLoadMoreProps({...loadMoreProps, loading: false}),
      });
    }
  }

  const handleCloseAuthModal = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    loadMoreProps.loading && setLoadMoreProps({...loadMoreProps, loading: false});
  }

  const handleOnSuccessAuth = () => {
    setAuthWindowProps({...authWindowProps, open: false});
    if (loadMoreProps.months === 1) {
      loadTransactions({months: 0});
    } else {
      handleLoadMore();
    }
  }

  const cancelButtonProps = {
    size: 'large'
  };

  const okButtonProps = {
    disabled: disabledOkButton,
    loading,
    type: 'primary',
    size: 'large'
  };

  return (
    <StyledAddTransactionModal
      cancelButtonProps={cancelButtonProps}
      okButtonProps={okButtonProps}
      okText={trans('okButton')}
      onOk={handleSubmitFiles}
      open={open}
      title={trans('title')}
      destroyOnClose={true}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <StyledAddTransactionModalSpace>
          <StyledAddTransactionModalInvoiceDetailsHeader>
            <div className='d-flex flex-column'>
              {date && (
                <span className='date'>
                  {helpers.getDateFromISO(date, 'DD MMM YYYY, HH:mm')}
                </span>
              )}
              <span className='vendor'>
                {vendor}
              </span>
            </div>
            <span className='price'>
              {amount}
            </span>
          </StyledAddTransactionModalInvoiceDetailsHeader>
          <StyledAddTransactionModalInvoiceDetailsHeaderSpace
            direction='vertical'
            size='middle'
          >
            <StyledAddTransactionModalAttachmentPreview>
              <InvoiceAttachmentPreview
                attachment={attachment}
              />
            </StyledAddTransactionModalAttachmentPreview>
          </StyledAddTransactionModalInvoiceDetailsHeaderSpace>
          <StyledAddTransactionModalRadioDescription>
            {trans('selectTransaction')}
          </StyledAddTransactionModalRadioDescription>
          <SpinSmall spinning={!transactions.loaded}>
            <Radio.Group
              onChange={(e) => setSelectedTransaction(e.target.value)}
              value={selectedTransaction}
            >
              <Space
                direction='vertical'
                size='small'
              >
                {transactions.data.map((transaction, index) => {
                  const name = transaction.source || transaction.description;
                  const amount = transaction?.amount || 0;
                  const isIncoming = transaction?.is_incoming || false;
                  const createdDate = transaction?.created_date || undefined;
                  const date = createdDate ? helpers.getDateWithMonth(createdDate, 'DD MMM YYYY') : '';
                  return (
                    <StyledAddTransactionModalTransactionRadio
                      key={`radio-${index}`}
                      value={transaction.id}
                    >
                      <Space size='small'>
                        <Avatar size='large'>
                          {helpers.getInitials(name)}
                        </Avatar>
                        <Space direction='vertical' size={2}>
                          <span className='vendor'>
                            {name}
                          </span>
                          <Space size='small'>
                            <span className='date'>
                              {date}
                            </span>
                            <span className={`price ${isIncoming ? '' : 'danger-text'}`}>
                              {isIncoming ? '' : '-'}{helpers.getMoneyView(amount)}
                            </span>
                          </Space>
                        </Space>
                      </Space>
                    </StyledAddTransactionModalTransactionRadio>
                  )
                })}
              </Space>
            </Radio.Group>
          </SpinSmall>

          <LoadMoreButton
            className='load-more-btn'
            isEnabled={loadMoreProps.isEnabled}
            isRequiredTwoFA={loadMoreProps.months >= numberOfMonthsWithoutAuth}
            loading={loading || loadMoreProps.loading}
            onClick={handleLoadMore}
            size='large'
          >
            {t('loadMore')}
          </LoadMoreButton>

          <UnmountClosed isOpened={error}>
            <Alert message={error} type='error' showIcon />
          </UnmountClosed>
        </StyledAddTransactionModalSpace>
      </SpinSmall>

      <AuthenticationWindow
        {...authWindowProps}
        authModalProps={{title: `${t('transactions')} ${t('loading')}`}}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={activeOperationName}
      />

    </StyledAddTransactionModal>
  );
}

AddTransactionModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  const {wireDetails} = state.transaction;

  return {
    wireDetails
  }
}

const mapDispatchToProps = {
  getTransactions: transactionActions.getTransactionsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTransactionModal);
